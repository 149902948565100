import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import { map } from 'ramda';
import { 
  Wrapper, 
  Title, 
  List, 
  Item, 
  ItemName, 
  ItemLink,
  ContactGroupWrapper,
  ContactGroupTitle
} from './styles';

const EmailContactItem = ({ name, email }) => (
  <Item key={name}>
    <ItemName>{name}</ItemName>
    <ItemLink href={`mailto:${email}`} target="_blank">{email}</ItemLink>
  </Item>
);

EmailContactItem.propTypes = {
  email: PropTypes.string.isRequired
};

const PhoneContactItem = ({ name, phone }) => (
  <Item key={name}>
    <ItemName>{name}</ItemName>
    <ItemLink href={`tel:${phone}`} target="_blank">{phone}</ItemLink>
  </Item>
);

PhoneContactItem.propTypes = {
  phone: PropTypes.string.isRequired
};

const ContactItem = ({ type, ...rest }) => {
  switch (type) {
    case config.contacts.CONTACT_ITEM_TYPES.EMAIL:
      return <EmailContactItem {...rest} />;
    case config.contacts.CONTACT_ITEM_TYPES.PHONE:
      return <PhoneContactItem {...rest} />;
    default:
      return null;
  }
};

ContactItem.propTypes = {
  type: PropTypes.string.isRequired
};

const Contacts = () => (
  <Wrapper>
    <Title>Contacts</Title>
    {map(({ name, items }) => (
      <ContactGroupWrapper>
        <ContactGroupTitle>{name}</ContactGroupTitle>
        <List>
          {map(item => <ContactItem {...item} />, items)}
        </List>
      </ContactGroupWrapper>
    ), config.contacts.CONTACTS)}
  </Wrapper>
);

export default Contacts;
