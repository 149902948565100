import React from 'react';
import t from '../texts';
import images from '../images';
import {
  Wrapper,
  Content,
  Heading,
  ConstructionUpdatesButton,
  StyledSlider as Slider,
  TextBg,
} from './styles';
import Contacts from 'components/Contacts';

export default () => (
  <Wrapper>
    <Slider images={images} />
    <TextBg />
    <Content>
      <h6>{t.subheading}</h6>
      <Heading>{t.heading}</Heading>
      <p>{t.description1}</p>
      <p>{t.description2}</p>
      <p>{t.description3}</p>
      <Contacts />
      <ConstructionUpdatesButton to="/construction-updates">
        {t.cta}
      </ConstructionUpdatesButton>
    </Content>
  </Wrapper>
)
