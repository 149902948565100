import { getTexts } from '../utilities/translations'

const t = getTexts({
  'en': {
    // Walking
    walkingTitle: `Within walking distance`,
    walking1Content: `St Pancras Railway Station (Eurostar)`,
    walking2Content: `King's Cross St Pancras Underground`,
    walking3Content: `UAL Central Saint Martins`,
    walking4Content: `University Central London`,
    walking1Secondary: `2 mins`,
    walking2Secondary: `5 mins`,
    walking3Secondary: `10 mins`,
    walking4Secondary: `15 mins`,

    // Transport
    transportTitle: `Nearby transport links`,
    transport1Content: `Oxford Circus`,
    transport2Content: `Bank station`,
    transport3Content: `London Bridge`,
    transport4Content: `Paddington`,
    transport1Secondary: `3 stops`,
    transport2Secondary: `4 stops`,
    transport3Secondary: `5 stops`,
    transport4Secondary: `6 stops`
  },
  'zh': {
    // Walking
    walkingTitle: `步行距离`,
    walking1Content: `圣潘克拉斯站 （欧洲之星）`,
    walking2Content: `国王十字站`,
    walking3Content: `UAL 中央圣馬丁学院`,
    walking4Content: `伦敦大学学院`,
    walking1Secondary: `2分钟`,
    walking2Secondary: `5分钟`,
    walking3Secondary: `10分钟`,
    walking4Secondary: `15分钟`,

    // Transport
    transportTitle: `附近车站`,
    transport1Content: `牛津圆環站`,
    transport2Content: `银行站`,
    transport3Content: `伦敦桥站`,
    transport4Content: `帕丁頓站`,
    transport1Secondary: `3个站`,
    transport2Secondary: `4个站`,
    transport3Secondary: `5个站`,
    transport4Secondary: `6个站`
  }
})

export default {
  WALKING_TITLE: t.walkingTitle,
  WALKING: [
    { icon: 'tube', content: t.walking1Content, secondary: t.walking1Secondary },
    { icon: 'train', content: t.walking2Content, secondary: t.walking2Secondary },
    { icon: 'poi', content: t.walking3Content, secondary: t.walking3Secondary },
    { icon: 'poi', content: t.walking4Content, secondary: t.walking4Secondary },
  ],
  TRANSPORT_TITLE: t.transportTitle,
  TRANSPORT: [
    { icon: 'tube', content: t.transport1Content, secondary: t.transport1Secondary },
    { icon: 'train', content: t.transport2Content, secondary: t.transport2Secondary },
    { icon: 'poi', content: t.transport3Content, secondary: t.transport3Secondary },
    { icon: 'poi', content: t.transport4Content, secondary: t.transport4Secondary },
  ]
}
