import React from 'react';
import MediaQuery from 'react-responsive';
import PageLayout from 'components/PageLayout';
import SEO from 'components/seo';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { sizes } from 'styles';

const HomePage = () => (
  <PageLayout restrictHeight={false}>
    <SEO title="Home" />
    <MediaQuery minWidth={sizes.TABLE_MAX_WIDTH + 1}>
      <Desktop />
    </MediaQuery>
    <MediaQuery maxWidth={sizes.TABLE_MAX_WIDTH}>
      <Mobile />
    </MediaQuery>
  </PageLayout>
);

export default HomePage;
