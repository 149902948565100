import styled from 'styled-components'
import Button from 'components/Button'
import Slider from 'components/Slider'
import { withPrefix } from 'gatsby'

export const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 55px;
`

export const LogoWrapper = styled.div`
  width: 100px;
  margin: 24px auto 37px;

  img {
    width: 100%;
    height: auto;
  }
`

export const Heading = styled.h2`
  margin: 25px 0;
`

export const StyledSlider = styled(Slider)`
  height: 60vw;
  min-height: 290px;
  margin-top: 40px;
`

export const ConstructionUpdatesButton = styled(Button).attrs({ block: 'true' })`
  margin: 50px 0 55px;
`

export const TextBg = styled.div`
  position: absolute;
  top: 70px;
  left: -90px;
  height: 700px;
  width: 250px;
  background: url(${withPrefix(`2020@2x.png`)}) center;
  background-size: 100%;
`
