const CONTACT_ITEM_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone'
};

export default {
  CONTACTS_TITLE: 'Contacts',
  CONTACT_ITEM_TYPES,
  CONTACTS: [{ 
    name: 'Main Contractor: Henry Construction Projects Limited', 
    items: [{
      type: CONTACT_ITEM_TYPES.EMAIL,
      name: 'Email',
      email: 'jmunteanu@henryconstruction.co.uk'
    }, {
      type: CONTACT_ITEM_TYPES.PHONE,
      name: 'Phone',
      phone: '+44 (0) 7721 064 981'
    }]
  }, { 
    name: 'Emergency / Out of Hours Security', 
    items: [{
      type: CONTACT_ITEM_TYPES.PHONE,
      name: 'Phone #1',
      phone: '0345 888 8333'
    }, {
      type: CONTACT_ITEM_TYPES.PHONE,
      name: 'Phone #2',
      phone: '0345 853 2020'
    }]
  }]
}
