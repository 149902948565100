import React from 'react';
import { Link } from 'gatsby';
import LogoImage from 'components/Images/LogoImage';
import t from '../texts';
import images from '../images';
import {
  Wrapper,
  TextBg,
  LogoWrapper,
  Heading,
  ConstructionUpdatesButton,
  StyledSlider as Slider
} from './styles';
import Contacts from 'components/Contacts';

export default () => (
  <Wrapper>
    <TextBg />
    <LogoWrapper>
      <Link to="/">
        <LogoImage />
      </Link>
    </LogoWrapper>
    <h6>{t.subheading}</h6>
    <Heading>{t.heading}</Heading>
    <Slider images={images} />
    <p>{t.description1}</p>
    <p>{t.description2}</p>
    <p>{t.description3}</p>
    <Contacts />
    <ConstructionUpdatesButton to="/construction-updates">
      {t.cta}
    </ConstructionUpdatesButton>
  </Wrapper>
)
